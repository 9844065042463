<template>
  <div class="min-h-screen bg-gray-50">
    <student-list></student-list>
  </div>
</template>

<script>
export default {
  components: {
    StudentList: () => import("@/components/Student/StudentList.vue"),
  },
};
</script>